.clientFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}

.clientGrid input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 10px;
}

.clientGridCompany input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 10px;
}

.clientitem11 {
  grid-area: company;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem12 {
  grid-area: businessNumber;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem1 {
  grid-area: firstname;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem2 {
  grid-area: lastname;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem3 {
  grid-area: phonenumber;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem4 {
  grid-area: email;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem5 {
  grid-area: staddress;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem6 {
  grid-area: town;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem7 {
  grid-area: zip;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem8 {
  grid-area: state;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem9 {
  grid-area: country;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientitem15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: empty;
}

.clientGridCompany {
  display: grid;
  gap: 10px;
  grid-template-areas:
    "company businessNumber empty"
    "firstname lastname empty"
    "phonenumber email email"
    "staddress town zip"
    "state country country";
}

.clientGrid {
  display: grid;
  gap: 10px;
  grid-template-areas:
    "firstname lastname empty"
    "phonenumber email email"
    "staddress town zip"
    "state country country";
}
