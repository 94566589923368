/* .mobileLogin input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
} */

.mobileLogin label {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
}

.mobileLogin input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  width: 390px;
  padding: 10px 16px 10px 16px;
  margin-top: 12px;
}

.textaboveInput {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* text-align: center; */
  padding: 16px 0px 14px 0px;
  max-width: 390px;
}

.button {
  /* display: flex; */
  width: 390px;
  padding: 16px;
  text-align: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 12px;
  color: #fff;
  background: #373cbd;
}
.button[disabled] {
  opacity: 60%;
  cursor: not-allowed;
}

.newSignUpLoginText {
  color: #161616;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
.columnTwoText {
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-top: 83px; */
}
.columnTwoTextPara {
  color: #d1d1d1;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
