.eventFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}

.leadformAlbum-heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  border-radius: 8px 8px 0px 0px;
}

.leadformAlbum-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  /* padding: 24px; */
}

/* .eventFormAlbumGridContainer p {
  color: #787878;
} */

.eventFormAlbumGridContainer input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 10px;
}
.eventFormAlbumGridContainer .formEventName {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: eventName;
}

.eventFormAlbumGridContainer .formEventType {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: eventType;
}

.eventFormAlbumGridContainer .formEventLocation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: eventLocation;
}

.eventFormAlbumGridContainer .formClientName {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: search;
  position: relative;
}

/* .eventFormAlbumGridContainer .formClientName p {
  width: 100%;
  max-width: 150px;
  text-align: center;
} */

.eventFormAlbumGridContainer .eventitem2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: orText;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.eventFormAlbumGridContainer .eventitem3 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  grid-area: addClientButton;
}

.eventFormAlbumGridContainer .eventitem4 {
  grid-area: leadText;
}

/* .eventFormAlbumGridContainer .item4 p {
  color: black;
} */

.eventFormAlbumGridContainer .eventitem5 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: leadInput;
}

/* .eventFormAlbumGridContainer .item5 p {
  width: 100%;
  max-width: 140px;
} */

.eventFormAlbumGridContainer .eventitem17 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: location;
}

/* .eventFormAlbumGridContainer .item17 p {
  width: 100%;
  max-width: 140px;
} */

.eventFormAlbumGridContainer .eventitem6 {
  grid-area: schedule;
}

/* .eventFormAlbumGridContainer .item6 p {
  color: black;
} */

.eventFormAlbumGridContainer .eventitem7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  grid-area: dateConfirm;
}

.eventFormAlbumGridContainer .eventitem7 input {
  outline-style: none;
}

/* .eventFormAlbumGridContainer .item7 label {
  max-width: 400px;
} */

.eventFormAlbumGridContainer .eventitem8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: startDate;
}

/* .eventFormAlbumGridContainer .item8 p {
  width: 100%;
  max-width: 140px;
} */

.eventFormAlbumGridContainer .eventitem9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  grid-area: toDateText;
}

.eventFormAlbumGridContainer .eventitem10 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-area: endDate;
}

.eventFormAlbumGridContainer .eventitem11 {
  grid-area: checkbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.eventFormAlbumGridContainer .eventitem11 input {
  outline-style: none;
}

/* .eventFormAlbumGridContainer .item11 label {
  width: 100%;
  max-width: 400px;
} */

.eventFormAlbumGridContainer .eventitem12 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: startTime;
}

/* .eventFormAlbumGridContainer .item12 p {
  width: 100%;
  max-width: 140px;
} */

.eventFormAlbumGridContainer .eventitem13 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  grid-area: toTimeText;
}

.eventFormAlbumGridContainer .eventitem14 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-area: endTime;
}

.eventFormAlbumGridContainer .eventitem15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: empty;
}

/* .eventFormAlbumGridContainer .item16 {
  grid-area: buttons;
} */

.eventFormAlbumGridContainer .eventitem18 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: notes;
}

/* .eventFormAlbumGridContainer .item18 p {
  width: 100%;
  max-width: 140px;
} */

.eventFormAlbumGridContainer {
  display: grid;
  gap: 20px;
  grid-template-areas:
    "eventName  eventName eventName  eventName  eventType eventType eventType eventType  eventLocation eventLocation eventLocation eventLocation "
    "search search search search search orText orText  orText addClientButton addClientButton addClientButton  addClientButton"
    "schedule schedule schedule schedule schedule schedule schedule schedule schedule schedule schedule schedule "
    "dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm dateConfirm"
    "startDate startDate startDate toDateText toDateText toDateText endDate endDate endDate checkbox checkbox checkbox"
    "startTime startTime startTime toTimeText toTimeText toTimeText endTime endTime endTime empty empty empty"
    "notes notes notes notes notes notes notes notes notes notes notes notes";
  /* "buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons"; */
}
