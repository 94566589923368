.leadformAlbum-heading {
    background-color: rgb(27, 40, 53);
    color: #fff;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}

.leadformAlbum-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    /* padding: 24px; */
}

.leadformAlbumgrid-container p {
    color: #787878;
}


.leadformAlbumgrid-container input {
    border-radius: 4px;
    background-color: #e3e9ff;
    outline-style: solid;
    outline-color: #787878;
    outline-width: 1px;
    padding-left: 12px;
    line-height: 32px;
    font-size: 15px;
}


.leadformAlbumgrid-container .item1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: search;
    position: relative;
}

.leadformAlbumgrid-container .item1 p {
    width: 100%;
    max-width: 150px;
    text-align: center;
}

.leadformAlbumgrid-container .item2 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: orText;

}

.leadformAlbumgrid-container .item3 {
    display: flex;
    justify-content: flex-end;
    grid-area: addClientButton;
}

.leadformAlbumgrid-container .item4 {
    grid-area: leadText;
}

.leadformAlbumgrid-container .item4 p {
    color: black;
}

.leadformAlbumgrid-container .item5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: leadInput;
}

.leadformAlbumgrid-container .item5 p {
    width: 100%;
    max-width: 140px;
}

.leadformAlbumgrid-container .item17 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: location;
}

.leadformAlbumgrid-container .item17 p {
    width: 100%;
    max-width: 140px;
}

.leadformAlbumgrid-container .item6 {
    grid-area: schedule;
}

.leadformAlbumgrid-container .item6 p {
    color: black;
}

.leadformAlbumgrid-container .item7 {
    grid-area: dateConfirm;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

}

.leadformAlbumgrid-container .item7 input {
    outline-style: none;
}

.leadformAlbumgrid-container .item7 label {
    max-width: 400px;
}

.leadformAlbumgrid-container .item8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: startDate;
}

.leadformAlbumgrid-container .item8 p {
    width: 100%;
    max-width: 140px;
}

.leadformAlbumgrid-container .item9 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: toDateText;

}

.leadformAlbumgrid-container .item10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: endDate;
}

.leadformAlbumgrid-container .item11 {
    grid-area: checkbox;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.leadformAlbumgrid-container .item11 input {
    outline-style: none;

}

.leadformAlbumgrid-container .item11 label {
    width: 100%;
    max-width: 400px;
}

.leadformAlbumgrid-container .item12 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: startTime;
}

.leadformAlbumgrid-container .item12 p {
    width: 100%;
    max-width: 140px;

}

.leadformAlbumgrid-container .item13 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: toTimeText;


}

.leadformAlbumgrid-container .item14 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: endTime;

}

.leadformAlbumgrid-container .item15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: empty;
}

.leadformAlbumgrid-container .item16 {
    grid-area: buttons;
}

.leadformAlbumgrid-container .item18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: notes;
}

.leadformAlbumgrid-container .item18 p {
    width: 100%;
    max-width: 140px;
}



.leadformAlbumgrid-container {
    /* margin-top: 12px; */
    display: grid;
    padding: 24px;
    gap: 10px;
    grid-template-areas:
        'search orText addClientButton  addClientButton'
        'leadText leadText leadText leadText '
        'leadInput leadInput  leadInput  leadInput'
        'location location location location'
        'schedule schedule schedule schedule '
        'dateConfirm dateConfirm dateConfirm dateConfirm'
        'startDate toDateText endDate checkbox'
        'startTime toTimeText endTime empty'
        'notes notes notes notes'
        'buttons buttons buttons buttons'
}