.AlbumCreateFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}


.workflowLeadAddAlbum-heading {
    background-color: rgb(27, 40, 53);
    color: #fff;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}

.workflowLeadAddAlbum-grid .row1 {
    grid-area: name;
    display: flex;
    flex-direction: row;
}

.workflowLeadAddAlbum-grid .row2 {
    grid-area: dropdown;
    display: flex;
    flex-direction: row;
}

.workflowLeadAddAlbum-grid .row5 {
    grid-area: buttons;
}

.workflowLeadAddAlbum-grid {
    display: grid;
    padding: 24px;
    gap: 10px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    grid-template-areas:
        'name name'
        'dropdown dropdown'
        'buttons buttons'
}

.workflowLeadAddAlbum-grid input {
    border-radius: 4px;
    /* width: 100%; */
    background-color: #e3e9ff;
    outline-style: solid;
    outline-color: #787878;
    outline-width: 1px;
    padding-left: 12px;
    line-height: 32px;
    font-size: 15px;
}

.workflowLeadAddAlbum-grid p {
    width: 100%;
    max-width: 120px;
    color: #787878;
}
/* YourComponent.css */

/* Styles for the popup container */
/* YourComponent.css */

/* Styles for the popup container */
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the popup is on top */
  }
  
  /* Styles for the popup itself */
  .popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%; /* Adjust the width of the popup as needed */
  }
  
  
  /* Styles for the popup content */
  .popup-content {
    text-align: center;
  }
  
  /* Styles for the property inputs */
  .property {
    margin-bottom: 10px;
  }
  
  /* Styles for the buttons in the popup */
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Other styles for your component */
  /* ... */
  