.clientContainer {
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  margin: 6px 6px;
}

.clientTable {
  padding: 16px 20px;
  z-index: 0;
}

.clientTable table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.clientTable table tr {
  border-bottom: 1px solid #dddddd;
}

.clientTable table th {
  text-align: left;
  padding: 8px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.clientTable table td {
  text-align: left;
  padding: 8px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.client-container {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.client-buttons {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
}

.client-search {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.client-search input {
  width: 20%;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.client-table {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.client-table table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 97%;
}

.client-table table td,
.client-table table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.client-table table tr:nth-child(even) {
  background-color: #dddddd;
}
