.faceTrainingDetailContainer {
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  margin: 6px 6px;
}

.faceTrainingDetailTable {
  padding: 5px 5px;
  z-index: 0;
}

.faceTrainingDetailTable table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.faceTrainingDetailTable table tr {
  border-bottom: 1px solid #dddddd;
}

.faceTrainingDetailTable table th {
  text-align: center;
  padding: 4px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.faceTrainingDetailTable table td {
  text-align: center;
  padding: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 133.333% */
}

.albumFolder-container {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.albumFolder-table {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.albumFolder-table table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 97%;
}

.albumFolder-table td,
.albumFolder-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* td {
    cursor: pointer;
}    */

.newBox {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
}

.folderName {
  color: #161616;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}
.uploadImageButFace {
  display: flex;
  padding: 6px 16px 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  color: #000;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 225% */
  text-transform: uppercase;
}

.publishBut {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #4d58e3;
  display: inline-flex;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 180% */
  text-transform: uppercase;
}
.advsettingdFace {
  white-space: nowrap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  display: inline-flex;
  padding: 8px 16px 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.albumType {
  color: #fead55;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
  white-space: nowrap;
}
