@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 8px 0;
}
.form p {
  color: #8c8c8c;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.form input,
.form textarea {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.form textarea {
  field-sizing: content;
}

.form input:read-only,
.form textarea:read-only {
  border: none;
  border-bottom: 1px solid #000000;
}
.form input[readonly]:focus,
textarea[readonly]:focus {
  border: none;
  border-bottom: 1px solid #000000;
}
.form textarea {
  min-height: 100px;
  resize: none;
  overflow: auto;
}
.form input[type="color"] {
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
}
.form input[type="radio"] {
  width: 30px;
  height: 30px;
}
.form input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
.form input:focus,
textarea:focus {
  border: 1px solid #000000;
}
.form .formRadio {
  width: 20px;
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.imageContainer {
  position: relative;
  height: 100px;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 5px;
}
.imageContainerMultiple {
  position: relative;
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 20px;
  column-gap: 8px;
  cursor: pointer;
}
.imageContainer button {
  border: 1px solid rgba(140, 140, 140, 0.2);
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #fff;
}
.changeImageButton {
  background: #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 8px;
  border: 1px solid rgba(140, 140, 140, 0.2);
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  text-transform: uppercase;
}
.addNewMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px dashed #4d58e3;
  width: 100%;
  height: 100%;
  color: #4d58e3;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 118.182% */
  text-align: center;
}
