.signOutdropdownContent {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  top: 20px;
  right: 30px;
  padding: 10px 0;

  border-radius: 4px;
  width: fit-content;
  min-width: 250px;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  border: 0.2px solid rgba(0, 0, 0, 0.1);
}
.signOutdropdownContent button {
  padding: 10px 20px;
  text-align: right;
  width: 100%;
}
.signOutdropdownContent button:hover {
  background-color: rgba(244, 244, 244, 0.845);
}
