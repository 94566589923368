.paymentText {
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.payTypeBox {
  padding: 16px;
  border-radius: 12px;

  background: #fff;
}
.rupees {
  color: #161616;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.ruppesEmi {
  color: #979494;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.oneTimeOrEmi {
  color: #101828;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.iconSel {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #4d58e3;
  background: #4d58e3;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.iconNotSel {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  background: #fff;
}
.PaySelBox {
  border-radius: 12px;
  border: 2px solid #4d58e3;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.expiry {
  color: #161616;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.storageinGBbut {
  border-radius: 4px;
  background: rgba(152, 196, 255, 0.2);
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #373cbd;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.fexiPrice {
  color: var(--Gray-900, #101828);
  font-family: Inter;

  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.featureData {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}
