.InvitationCreateFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  /* filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25)); */
  height: fit-content;
}
.InvitationCreateFormContainer .buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
}
.sectionPopupContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}

.workflowLeadAddAlbum-heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  border-radius: 8px 8px 0px 0px;
}

.workflowLeadAddAlbum-grid .row1 {
  grid-area: name;
  display: flex;
  flex-direction: row;
}

.workflowLeadAddAlbum-grid .row2 {
  grid-area: dropdown;
  display: flex;
  flex-direction: row;
}

.workflowLeadAddAlbum-grid .row5 {
  grid-area: buttons;
}

.workflowLeadAddAlbum-grid {
  display: grid;
  padding: 24px;
  gap: 10px;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  grid-template-areas:
    "name name"
    "dropdown dropdown"
    "buttons buttons";
}

.workflowLeadAddAlbum-grid input {
  border-radius: 4px;
  /* width: 100%; */
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.workflowLeadAddAlbum-grid p {
  width: 100%;
  max-width: 120px;
  color: #787878;
}
