@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.portfolio {
  padding: 16px;
  width: 100%;
}
.portfolio h1 {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.portfolio button {
  border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #161616;
  padding: 11px 28px;
  color: #f6f6f6;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.portfolio .buttonSecondary {
  border-radius: 8px;
  border: 1px solid #161616;
  padding: 11px 28px;
  background: #f6f6f6;
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
