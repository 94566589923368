@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.outlineNone {
  outline: none;
}

.seperatorHorizontal {
  border: 1px solid #e9ebed;
  width: 100%;
  height: 0px;
}

.seperatorHorizontalNew {
  background: rgba(22, 22, 22, 0.1);
  width: 100%;
  height: 1px;
}

.seperatorVertical {
  border: 1px solid #e9ebed;
  width: 0px;
  height: 100%;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.cancelButton {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 11px 0px;
  width: 130px;
  background-color: white;
  color: black;
  margin: 0;
  justify-content: center;
}

.eventButton {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #373cbd;
  color: #ffff;
  /* border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #161616;
  padding: 11px 18px;
  width: fit-content;
  color: white;
  margin: 0;
  justify-content: center;
  cursor: pointer; */
}
.publishButton {
  border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #0a8425de;
  padding: 7px 0px;
  width: 130px;
  color: white;
  margin: 0;
  justify-content: center;
  cursor: pointer;
}

.eventButtonShort {
  background: #161616;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px 5px 5px;
  border-radius: 4px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  cursor: pointer;
}

.eventButtonShortPadding {
  background: #161616;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  cursor: pointer;
}

.shortButton {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px 5px 5px;
  border-radius: 4px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  cursor: pointer;
}

.shortButtonBlack {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px 5px 5px;
  border-radius: 4px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  cursor: pointer;
  background-color: #161616;
}
/* custom scollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #16161626;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.text_1Line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font_semibold_12 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}

.font_semibold_14 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}

.font_semibold_16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
}

.font_semibold_18 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.font_semibold_20 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_11 {
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_12 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_13 {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_15 {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.font_medium_16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.font_normal_18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.36px;
}

.font_medium_18 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.36px;
  text-align: left;
}

.font_medium_22 {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.font_bold_21 {
  font-size: 21px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 140%; /* 29.4px */
  letter-spacing: 0em;
  text-align: left;
}
.font_medium_32 {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}
.font_normal_11 {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.font_normal_12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.font_normal_13 {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.font_normal_14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}
.font_normal_16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}
.font_normal_20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.seperator {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
}

/* choosepackage */

.storageShowButton {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.26px;
}
.packageExtracharge {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}
.packageHeading {
  font-family: Inter;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
.planName {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}
.planPrice {
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.planSwitchingDiv {
  display: inline-flex;
  padding: 4px;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  background: #fff;
}
.planSwitchingButton {
  display: flex;
  width: 158px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;

  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.skipButton {
  color: #373cbd;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.purchaseContinue {
  display: inline-flex;
  padding: 16px 62px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #373cbd;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.dateFilter {
  border-radius: 8px;
  background: rgba(217, 217, 217, 0.5);
  flex-shrink: 0;
}
.buttonDate {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  justify-items: center;
  align-items: center;
  display: flex;
  height: 28px;
  flex-shrink: 0;
}
.trailPlan {
  color: #373cbd;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.prevNextBut {
  border-radius: 50px;
  
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 40px;
  height: 40px;
}

/* No Data */

.NoDataHead {
  color: #373cbd;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
.NoDataText {
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

/* Pop Up shadow */

.popUpShadow {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

.shadowHover:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 32px 0px;
}
.contactCard {
  width: 280px;
  height: 464px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(155deg, #8a56ff 2.08%, #3c77f6 97.58%);
}

.contactHead {
  color: #f6f6f6;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
.contactHeadText {
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}
.contactEmail {
  color: #f6f6f6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.contactBut {
  display: flex;
  width: 178px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #fff;
  color: #161616;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.26px;
}
