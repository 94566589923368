.GalleryCreateFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}



.heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  border-radius: 8px 8px 0px 0px;
}

.grid-container p {
  color: #787878;
}

.grid-container-company p {
  color: #787878;
}

.grid-container input {
  border-radius: 4px;
  width: 100%;
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.grid-container-company input {
  border-radius: 4px;
  width: 100%;
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.item0 {
  grid-area: checkbox;
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: center;
  padding-left: 24px;
  padding-top: 24px;
}

.item11 {
  grid-area: company;
}

.item12 {
  grid-area: businessNumber;
}

.item1 {
  grid-area: firstname;
}

.item2 {
  grid-area: lastname;
}

.item3 {
  grid-area: phonenumber;
}

.item4 {
  grid-area: email;
}

.item5 {
  grid-area: staddress;
}

.item6 {
  grid-area: town;
}

.item7 {
  grid-area: zip;
}

.item8 {
  grid-area: state;
}

.item9 {
  grid-area: country;
}

.item10 {
  grid-area: buttons;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  /* padding: 24px; */
}

.grid-container-company {
  /* margin-top: 12px; */
  display: grid;
  padding: 24px;
  gap: 10px;
  grid-template-areas:
    "checkbox checkbox"
    "company company"
    "businessNumber businessNumber"
    "firstname lastname"
    "phonenumber phonenumber"
    "email email"
    "staddress staddress"
    "town zip"
    "state state"
    "country country"
    "buttons buttons";
}

.grid-container {
  /* margin-top: 12px; */
  display: grid;
  padding: 24px;
  gap: 10px;
  grid-template-areas:
    "checkbox checkbox"
    "firstname lastname"
    "phonenumber phonenumber"
    "email email"
    "staddress staddress"
    "town zip"
    "state state"
    "country country"
    "buttons buttons";
}
