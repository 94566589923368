@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.dottedSectionContainerWhite {
  border-radius: 12px;
  border: 1px dashed rgba(246, 246, 246, 0.5);
  position: relative;
  color: #161616;
  padding: 60px 20px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dottedSectionTitleWhiteAbsolute {
  position: absolute;
  top: 0;
  left: 20px;
  transform: translate(0, -50%);
  border-radius: 4px;
  border: 1px solid #8b8b98;
  background: #161616;
  padding: 4px 12px;
}

.dottedSectionTitleWhiteAbsolute p {
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.dottedSectionTitleWhiteAbsolute span {
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
  opacity: 0.5;
  margin-left: 6px;
}
.imageSection {
  width: 150px;
  height: 110px;
  border-radius: 12px;
  opacity: 1;
  background: #2e2e2e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.details {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px 40px;
}
.details h2 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  letter-spacing: -0.42px;
  margin-bottom: 10px;
}
.details h3 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  margin-bottom: 19px;
}
.details p {
  opacity: 0.6;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}
.replaceButton {
  border-radius: 8px;
  border: 1px solid rgba(139, 139, 152, 0.2);
  background: #232323;
  display: inline-flex;
  padding: 4px 12px 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #efeeee;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
}
