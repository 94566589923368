.eventDetailContainer {
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  margin: 6px 6px;
}

.eventDetailDateWidth {
  float: left;
  width: 6.32%;
  height: 100%;
}

.eventDetailSideSeperator {
  border-right: 2px solid rgba(140, 140, 140, 0.3);
}

.flex1 {
  flex: 1;
}

.eventDetailsTable {
  padding: 16px 20px;
  z-index: 0;
}

.eventDetailsTable table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.eventDetailsTable table tr {
  border-bottom: 1px solid #dddddd;
}

.eventDetailsTable table th {
  text-align: center;
  padding: 8px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.eventDetailsTable table td {
  text-align: center;
  padding: 8px;
}

.leadLayout {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.leadDetails {
  margin-top: 25px;
  margin-left: 25px;
  width: 90%;
  height: auto;
  padding: 5px;
  text-align: left;
}

.albumDetails {
  background: #f3f5f7;
  border: 1px solid #f2f8ff;
  border-radius: 6px 6px 0px 0px;
}

.clientLeadDetails {
  border: 1px solid #e9ebed;
  border-radius: 6px;
}

.leadDetailViewHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px 30px 0px;
}

/* NEW DESIGN */

.eventCreateClass {
  display: flex;
  padding: 8px 12px 8px 8px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.dateNumber {
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.eventType {
  color: #fead55;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}

.eventName {
  color: #161616;

  /* Title 3 */
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 126.316% */
}
.serviceLength {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.service {
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  text-transform: uppercase;
  opacity: 0.54;
}

.buttonServiceName {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.buttonServiceNameSel {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #4d58e3;
  color: #4d58e3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.serviceListDiv {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
}
.storageText {
  color: #161616;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 14px */
}

.copyLink {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.daysAgo {
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.serviceName {
  color: #161616;

  /* Title 3 */
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 126.316% */
}
.serviceType {
  color: #b4b2b2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  /* text-transform: uppercase; */
}

.paymentErrorBoxDiv {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #ffebeb;
  color: #fa6666;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}

.viewInviceBut {
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.servicePrice {
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.payServiceBut {
  display: inline-flex;
  padding: 6px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #4d58e3;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
}
.exceedStoragePlace {
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid rgba(140, 140, 140, 0.3);
  border-bottom: 1px solid rgba(140, 140, 140, 0.3);
  border-left: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
}
.extraSpace {
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
}

.totalStorageBut {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #fa6666;
  color: #fff;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 11.2px */
}
.payDueStorageBut {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #4d58e3;
  color: #fff;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 11.2px */
}
