.leadform-heading {
    background-color: rgb(27, 40, 53);
    color: #fff;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}

.leadformgrid-container p {
    color: #787878;
}


.leadformgrid-container input {
    border-radius: 4px;
    background-color: #e3e9ff;
    outline-style: solid;
    outline-color: #787878;
    outline-width: 1px;
    padding-left: 12px;
    line-height: 32px;
    font-size: 15px;
}


.leadformgrid-container .item1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: search;
    position: relative;
}

.leadformgrid-container .item1 p {
    width: 100%;
    max-width: 150px;
    text-align: center;
}

.leadformgrid-container .item2 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: orText;

}

.leadformgrid-container .item3 {
    display: flex;
    justify-content: flex-end;
    grid-area: addClientButton;
}

.leadformgrid-container .item4 {

    grid-area: leadText;
}

.leadformgrid-container .item4 p {
    color: black;

}

.leadformgrid-container .item5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: leadInput;
}

.leadformgrid-container .item5 p {
    width: 100%;
    max-width: 140px;
}

.leadformgrid-container .item6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: jobType;
}

.leadformgrid-container .item6 p {
    width: 100%;
    max-width: 140px;
}

.leadformgrid-container .item7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: leadType;
}

.leadformgrid-container .item7 p {
    width: 100%;
    max-width: 140px;
}

.leadformgrid-container .item8 {

    grid-area: schedule;
}

.leadformgrid-container .item8 p {
    color: black;

}

.leadformgrid-container .item9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: startDate;
}

.leadformgrid-container .item9 p {
    width: 100%;
    max-width: 140px;
}



.leadformgrid-container .item10 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: toDateText;

}

.leadformgrid-container .item11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: endDate;
}

.leadformgrid-container .item12 {
    grid-area: checkbox;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.leadformgrid-container .item12 input {
    outline-style: none;
    
}

.leadformgrid-container .item12 label {
    width: 100%;
    max-width: 400px;
}

.leadformgrid-container .item13 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: time;
}

.leadformgrid-container .item13 p {
    width: 100%;
    max-width: 140px;
    
}

.leadformgrid-container .item14 {
    margin-left: 50px;
    display: flex;
    align-items: center;
    grid-area: toTimeText;
    

}

.leadformgrid-container .item15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: endTime;
  
}

.leadformgrid-container .item16 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: empty;
   
}

.leadformgrid-container .item17 {
    grid-area: dateConfirm;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    
}

.leadformgrid-container .item17 input {
    outline-style: none; 
}

.leadformgrid-container .item17 label {
    max-width: 400px;
}

.leadformgrid-container .item18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: workflow;
}

.leadformgrid-container .item18 p {
    width: 100%;
    max-width: 140px;
}


.leadform-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    /* padding: 24px; */
}

.leadformgrid-container {
    /* margin-top: 12px; */
    display: grid;
    padding: 24px;
    gap: 10px;
    grid-template-areas:
        'search orText addClientButton  addClientButton'
        'leadText leadText leadText leadText '
        'leadInput leadInput  leadInput  leadInput'
        'jobType jobType jobType jobType '
        'workflow workflow workflow workflow'
        'leadType leadType  leadType  leadType'
        'schedule schedule schedule schedule '
        'dateConfirm dateConfirm dateConfirm dateConfirm'
        'startDate toDateText endDate checkbox'
        'time toTimeText endTime empty'

}