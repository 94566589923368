.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}
.container {
  padding: 0 16px 30px;
  background: #ffffff;
  color: #000000;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 45px;
  width: 50%;
  margin: 0 auto;
  color: #f6f6f6;
  background: #161616;
}
.input {
  color: #000;
  width: 100%;
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  padding: 12px;
  outline: none;
  resize: none;
}
