.studioDropdown {
  position: relative;
}

.studioDropDownButton {
  gap: 4px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  z-index: 50;
  padding: 8px 4px;
  width: max-content;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

.studioDropDownButton :hover {
  background: rgb(215, 213, 213);
}
