@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.portfolioCreate h1 {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.button {
  border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #161616;
  padding: 11px 28px;
  color: #f6f6f6;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  width: fit-content;
}

.buttonSecondary {
  border-radius: 8px;
  border: 1px solid #161616;
  padding: 11px 28px;
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.formGrid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
  padding: 20px;
}
.innerDiv {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  margin-bottom: 20px;
}
.innerDiv h3 {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid rgba(140, 140, 140, 0.3);
}

.line {
  border-radius: 8px;
  width: 100%;
  height: 1px;
  background: rgba(140, 140, 140, 0.3);
}

@media (min-width: 768px) {
  .formGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
