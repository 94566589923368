.popUpContainer {
  backdrop-filter: blur(100px);
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  left: 0;
  padding: 20px;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn  {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.contentBox {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 120px 0px rgba(67, 67, 67, 0.1);
  width: 100%;
  max-width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  white-space: nowrap;
}

.buyNowButton:hover {
  opacity: 0.8;
}

.tryDemoButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.tryDemoButton:hover {
  opacity: 0.8;
}
