.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 8px 0;
}
.form p {
  color: #8c8c8c;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.form input {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}
.form input:focus {
  border: 1px solid #000000;
}
.form .inputError {
  border: 1px solid rgb(255, 41, 41);
}
.error {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  opacity: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
