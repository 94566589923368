.button {
  border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #161616;
  padding: 11px 28px;
  color: #f6f6f6;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  margin-top: 20px;
}
.portfolioPayment h1 {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  max-width: 60%;
  text-align: center;
}
