.kb-file-upload {
    transition: background-color 0.3s ease;
}

.drag-over {
    background-color: #157dad;
}

.file-progress-bar {
    width: 100%;
    height: 10px;
    background-color: #818181;
    margin-top: 5px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #007bff; 
    transition: width 0.3s ease-in-out;
  }
  