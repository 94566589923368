.dropdownContent {
  display: flex;
  flex-direction: column;
  width: 232px;
  position: absolute;
  background-color: white;
  height: 450px;
  overflow-y: scroll;
  top: 40px;
  left: -240px;
  padding: 0px 10px;
  border-radius: 12px;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.dropdownList {
  display: flex;
  gap: 8px;
  padding: 4px 0px 4px 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}
