@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.portfolio {
  padding: 16px;
  width: 100%;
}
.portfolio h1 {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.portfolio button {
  border-radius: 8px;
  border: 1px solid rgba(255, 250, 250, 0.8);
  background: #161616;
  padding: 11px 28px;
  color: #f6f6f6;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.portfolio .buttonSecondary {
  border-radius: 8px;
  border: 1px solid #161616;
  padding: 11px 28px;
  background: #f6f6f6;
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.selectContainer {
  display: inline-block;
  position: relative;
}
.selectContainer select {
  background-color: #f6f6f6; /* White background */
  color: #161616; 
  border-radius: 8px;
  border: 1px solid #161616; 
  padding: 11px 28px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.dotInLeads {
  width: 32px;
  height: 32px;
  border-radius: 48px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bigDropme {
  width: 211px;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgb(0 0 0 / 10%), 0px 9px 46px rgb(0 0 0 / 6%),
    0px 24px 38px rgb(0 0 0 / 4%);
  border-radius: 8px;
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 11;
  padding: 16px;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -moz-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -ms-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}
