.eventContainer {
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  margin: 6px 6px;
}

.sideSeperator {
  border-right: 2px solid rgba(140, 140, 140, 0.3);
}

.albumTable {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.albumTable thead th {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 0px 9px 0px;
  background-color: #fdfdfd;
  text-align: left;
}

.albumTable tbody td,
.albumTable tbody tr {
  text-align: left;
  padding: 22px 0px 16px 0px;
  background-color: #fdfdfd;
}

.albumTable tbody td {
  border-top: 2px solid #efeffd;
  border-bottom: 2px solid #efeffd;
}

.albumTable thead th:first-child {
  padding-left: 20px;
}

.albumTable tbody td:first-child {
  border-left: 2px solid #efeffd;
  padding-left: 20px;
  border-radius: 6px;
}

.albumTable tbody td:last-child {
  border-right: 2px solid #efeffd;
  border-radius: 6px;
}

/* .albumTable tr{
    border: 1px solid #dddddd;
    padding: 20px;
} */

/* tr:nth-child(even) {
    background-color: #dddddd;
} */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.eventTable {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.dateWidth {
  float: left;
  width: 6.32%;
  height: 100%;
}

.eventNameWidth {
  float: left;
  width: 51.89%;
  height: 100%;
}

.eventLocationWidth {
  float: left;
  width: 35.47%;
  height: 100%;
}

.eventDetailsWidth {
  float: left;
  width: 19.47%;
  height: 100%;
}

.refreshButEvents {
  display: inline-flex;
  padding: 2px 12px 2px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

.eventsLength {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
