.prevNextLive {
  border-radius: 50px;
  border: 1px solid #d2d2d2;
  background: #fff;
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.imageLen {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #f4f4f4;
}
