.studioContainer {
  /* border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  margin: 6px 6px; */
}

.studioTable {
  padding: 16px 20px;
  z-index: 0;
}

.studioTable table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.studioTable table tr {
  border-bottom: 1px solid #dddddd;
}

.studioTable table th {
  text-align: left;
  padding: 8px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.studioTable table td {
  text-align: left;
  padding: 8px;
  color: #161616;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}