@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.parentContainer {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarContainer {
  width: 100%;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 84px;
  z-index: 70;
}

.navbar {
  width: 100%;
  max-width: 850px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #161616;
}

.navbar div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.previewButton {
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #454343;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  flex-shrink: 0;
}

.buyNowBlackButton {
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #161616;
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  cursor: pointer;
  white-space: nowrap;
}

.buyNowBlackButton:hover {
  opacity: 0.8;
}

.buyNowButton {
  padding: 7px 20px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  cursor: pointer;
  white-space: nowrap;
}

.buyNowButton:hover {
  opacity: 0.8;
}

.templateContainer {
  width: 100%;
  max-width: 850px;
  filter: drop-shadow(0px 4px 60px rgba(0, 0, 0, 0.12));
  border-radius: 24px;
  overflow: hidden;
  background: #fff;
}

.containerBlackSection {
  background: #161616;
  padding: 0px 20px;
}

.addVenuesButton {
  margin-top: 30px;
  color: #0c8ce9;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.26px;
  width: 100%;
  text-align: center;
}

.CoverImageAndTitleContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.coverContainer {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #161616;
  position: relative;
}

.coverContainer span {
  margin-top: 10px;
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  opacity: 0.6;
}

.coverContainer p {
  margin-top: 8px;
  max-width: 350px;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
  opacity: 0.4;
}

.coverContainer button {
  margin-top: 11px;
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0c8ce9;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
}

.coverContainerGallery {
  width: 100%;
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #161616;
  position: relative;
}

.coverContainerGallery span {
  margin-top: 10px;
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  opacity: 0.6;
}

.coverContainerGallery p {
  margin-top: 8px;
  max-width: 350px;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
  opacity: 0.4;
}

.coverContainerGallery button {
  margin-top: 11px;
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0c8ce9;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
}

.replaceButton {
  position: absolute;
  right: 18px;
  bottom: 18px;
  display: flex;
  padding: 4px 12px 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(139, 139, 152, 0.2);
  background: #fff;
  cursor: pointer;
}

.replaceButton span {
  margin-top: 0;
  opacity: 1;
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
}

.addMoreGalleryButton {
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 4px 8px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(139, 139, 152, 0.2);
  background: #fff;
  cursor: pointer;
}

.addMoreGalleryButton span {
  margin-top: 0;
  opacity: 1;
  color: #161616;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 183.333% */
  letter-spacing: -0.24px;
}

.previewEventTitle {
  color: #ff4545;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  letter-spacing: -0.42px;
  text-transform: uppercase;
  opacity: 0.9;
  margin-top: 22px;
}

.previewDateTimeHead {
  color: rgba(3, 6, 34, 0.9);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  opacity: 0.6;
}

.previewDate {
  color: rgba(3, 6, 34, 0.9);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
  opacity: 0.9;
}

.invitationBox {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(101, 100, 100, 0.12);
}

.titleSpan {
  color: #462f1d;
  font-family: "DM Serif Display";
  font-size: 58px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%; /* 58px */
}

.titleContainer {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
}

.titleContainer textarea {
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #fff;
  padding: 16px;
  color: #161616;
  text-align: center;
  font-family: "DM Serif Display";
  font-size: 34px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%; /* 40.8px */
  transition: border-color 0.3s ease;
  outline: none;
  field-sizing: content;
}

.titleContainer textarea:focus {
  border: 1px solid #0c8ce9;
}

.titleContainer textarea::placeholder {
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  opacity: 0.6;
  /* min-width: 380px; */
  height: fit-content;
}

.dottedSectionContainer {
  width: 100%;
  border-radius: 12px;
  border: 1px dashed rgba(22, 22, 22, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #161616;
  padding: 0px 20px;
}

.dottedSectionTitleAbsolute {
  position: absolute;
  top: 0;
  left: 20px;
  transform: translate(0, -50%);
  border-radius: 4px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #fff;
  padding: 4px 12px;
}

.dottedSectionTitleAbsolute p {
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.dottedSectionTitleAbsolute span {
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
  opacity: 0.5;
  margin-left: 6px;
}

.inputField {
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #fff;
  padding: 16px;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 23.4px */
  white-space: nowrap;
  transition: border-color 0.3s ease;
  outline: none;
  field-sizing: content;
}

.inputField:focus {
  border: 1px solid #0c8ce9;
}

.inputField::placeholder {
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  opacity: 0.6;
}

.getDirectionButton {
  border-radius: 12px;
  background: #161616;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  max-width: 385px;
  width: fit-content;
}

.getDirectionButton input {
  max-width: 100%;
  background: #161616;
  color: #8b8b98;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  border: none;
  field-sizing: content;
}

.getDirectionButton input::placeholder {
  color: #8b8b98;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 138.462% */
  letter-spacing: -0.26px;
}

.getDirectionButton input:focus {
  border: none;
}

.urlInput {
  border-radius: 8px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #fff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: fit-content;
  max-width: 100%;
}

.urlInput input {
  max-width: 100%;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
  border: none;
  field-sizing: content;
  background: #fff;
  opacity: 0.6;
}

.urlInput span {
  color: #0c8ce9;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  cursor: pointer;
}

.videoContainer {
  width: 385px;
  height: 252px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #f6f5f5;
  margin-top: 24px;
  overflow: hidden;
}

.dottedSectionContainerWhite {
  border-radius: 12px;
  border: 1px dashed rgba(246, 246, 246, 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #161616;
  padding: 60px 20px;
}

.dottedSectionContainerWhite textarea {
  border-radius: 8px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #232323;
  width: 100%;
  max-width: 385px;
  min-height: 90px;
  outline: none;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 138.462% */
  letter-spacing: -0.26px;
  text-align: center;
  padding: 16px;
}

.dottedSectionContainerWhite textarea::placeholder {
  outline: none;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 138.462% */
  letter-spacing: -0.26px;
  text-align: center;
}

.dottedSectionContainerWhite button {
  border-radius: 8px;
  background: #0c8ce9;
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
  cursor: pointer;
}
.dottedSectionTitleWhiteAbsolute {
  position: absolute;
  top: 0;
  left: 20px;
  transform: translate(0, -50%);
  border-radius: 4px;
  border: 1px solid #8b8b98;
  background: #161616;
  padding: 4px 12px;
}

.dottedSectionTitleWhiteAbsolute p {
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.dottedSectionTitleWhiteAbsolute span {
  color: #f6f6f6;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
  opacity: 0.5;
  margin-left: 6px;
}

.thanksImageSection {
  width: 160px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 12px;
  opacity: 1;
  background: #2e2e2e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thanksNoteFrom {
  border-radius: 8px;
  border: 1px dashed rgba(22, 22, 22, 0.2);
  background: #232323;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.thanksNoteFrom input {
  background: none;
  outline: none;
  color: #fff;
  /* field-sizing: content; */
  width: 100%;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 23.4px */
}

.thanksNoteFrom input::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  opacity: 0.6;
}

.thanksNoteFrom span {
  color: #f6f6f6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 137.5% */
  letter-spacing: -0.32px;
  flex-shrink: 0;
}

@media (max-width: 1023px) {
  .navbarContainer {
    width: 100%;
    background: #fff;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    position: sticky;
    top: 74px;
    z-index: 80;
  }

  .templateContainer {
    width: 100%;
    filter: none;
    border-radius: 0px;
    overflow: hidden;
  }

  .coverContainer p {
    max-width: 280px;
  }

  .coverContainerGallery p {
    max-width: 280px;
  }

  .previewEventTitle {
    margin-top: 14px;
  }
}

@media (max-width: 640px) {
  .CoverImageAndTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coverContainer {
    height: 350px;
  }

  .titleSpan {
    color: #462f1d;
    text-align: center;
    font-family: "DM Serif Display";
    font-size: 34px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%; /* 40.8px */
  }

  .titleContainer {
    margin-top: 24px;
  }

  .titleContainer textarea {
    width: 100%;
    font-size: 24px;
  }

  .titleContainer textarea::placeholder {
    font-size: 13px;
  }

  .dottedSectionContainer {
    padding: 0px 15px;
  }

  .dottedSectionContainerWhite {
    padding: 60px 15px;
  }

  .inputField {
    width: 100%;
    font-size: 18px;
  }

  .inputField::placeholder {
    font-size: 11px;
  }

  .urlInput input {
    width: 100%;
    font-size: 18px;
  }

  .urlInput input::placeholder {
    font-size: 11px;
  }

  .videoContainer {
    width: 100%;
    height: 214px;
    margin-top: 20px;
  }

  .getDirectionButton {
    max-width: 300px;
  }

  .thanksNoteFrom input {
    width: 100%;
    font-size: 18px;
  }

  .thanksNoteFrom input::placeholder {
    font-size: 11px;
  }

  .thanksNoteFrom span {
    font-size: 13px;
  }
}
