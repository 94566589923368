.progressContainer {
  height: 5px;
  background-color: transparent;
  width: 100%;
}
.progressBar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.progressContainerBlack {
  height: 4px;
  background-color: #D9D9D9;
  width: 100%;
}

.progressBarBlack {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
}
