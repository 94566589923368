.packageSection {
  width: 100%;
  gap: 12px;
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  overscroll-behavior-x: none;
}

.cards {
  border-radius: 12px;
  border: 1px solid rgba(120, 120, 120, 0.16);
  cursor: default;
  width: 100%;
  flex-shrink: 0;

}
.selectedCard {
  border-radius: 12px;
  border: 4px solid rgba(8, 166, 108, 0.8);
  box-shadow: 0 0 10px rgba(14, 135, 137, 0.3);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.cardsHeading {
  background-color: #17064a;
  padding: 32px 0px 24px 0px;
  width: 100%;
  text-align: center;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.cardsPrice {
  padding: 10px 0px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.cardsDescription {
  padding: 24px 18px;
}

.buyNowSection {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
}

.tryDemoButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
}

.tryDemoButton :hover {
  opacity: 0.8;
}

.buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
}

.buyNowButton:hover {
  opacity: 0.8;
}

.shootmatesHead {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 60px;
  text-align: center;
  color: #161616;
}

.filterFaceDetection {
  display: inline-flex;
  padding: 4px;
  border-radius: 50px;
  background: #ededed;
}
.filterChip {
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
}

.collaboration {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.memshotsLogo {
  width: 137px;
  height: 40px;
}

.crossIcon {
  width: 32px;
  height: 32px;
}

.shootmatesLogo {
  width: 150px;
  height: 26px;
}

@media (max-width: 1024px) {
  .packageSection {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 30px;
  }

  .cards {
    max-width: 280px;
    transition: none;
  }

  .cards:hover {
    transform: none;
  }

  .buyNowSection {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  .shootmatesHead {
    margin-top: 40px;
    text-align: left;
  }

  .collaboration {
    align-items: flex-start;
    text-align: left;
  }

  .memshotsLogo {
    width: 90px;
    height: 26px;
  }

  .crossIcon {
    width: 24px;
    height: 24px;
  }

  .shootmatesLogo {
    width: 98px;
    height: 17px;
  }
}
