.albumTable {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;

}

.albumTable thead th {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding: 15px 0px 9px 0px;
    background-color: #FDFDFD;
    text-align: left;
}


.albumTable tbody td,
.albumTable tbody tr {
    text-align: left;
    padding: 22px 0px 16px 0px;
    background-color: #FDFDFD;
}

.albumTable tbody td {
    border-top: 2px solid #EFEFFD;
    border-bottom: 2px solid #EFEFFD;


}

.albumTable thead th:first-child {
    padding-left: 20px;
}

.albumTable tbody td:first-child {
    border-left: 2px solid #EFEFFD;
    padding-left: 20px;
    border-radius: 6px;
}

.albumTable tbody td:last-child {
    border-right: 2px solid #EFEFFD;
    border-radius: 6px;
}

/* .albumTable tr{
    border: 1px solid #dddddd;
    padding: 20px;
} */

/* tr:nth-child(even) {
    background-color: #dddddd;
} */