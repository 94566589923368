.activePackageHead {
  color: #161616;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 85.714% */
}
.activePackagesMain {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  padding: 20px;
}
.activePackagesSub {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
  padding: 20px;
}

.CurrentPlan {
  color: #161616;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.memberSince {
  display: flex;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(152, 196, 255, 0.2);
}

.pacckageDetails {
  color: #161616;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.pacckageDetailsPrice {
  color: #161616;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.box {
  border-radius: 12px;
  border: 1px solid #efefef;
  background: #fff;
}
.paymentPlanType {
  color: #475467;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.storageSeekingBar {
  border-radius: 4px;
  background: #e4e7ec;
  height: 8px;
  flex-shrink: 0;
}
.storageSpace {
  border-radius: 28px;
  border: 4px solid #f9f5ff;
  background: #f4ebff;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.storageSpaceText {
  color: #344054;

  /* Text sm / Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.accordion details {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.accordion summary {
  background-color: #f9f9f9;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
}

.accordion summary:hover {
  background-color: #ececec;
}

.accordion div {
  background-color: #fff;
  padding: 16px;
  font-size: 14px;
  color: #333;
}
.EmiOfPackage {
  color: #161616;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.totalAmt {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.processFee {
  color: #475467;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.oneOfEmi {
  color: #161616;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.BillCreated {
  color: #475467;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.dateBut {
  display: flex;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(152, 196, 255, 0.2);
  color: #373cbd;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}
.dateButDue {
  display: flex;
  color: #fa6666;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(250, 102, 102, 0.2);
}
.payStatus {
  display: flex;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(19, 187, 130, 0.2);
  color: #13bb82;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}
.payNowBut {
  display: flex;
  padding: 10px 60px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #4d58e3;
  background: #4d58e3;
  color: #fff;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.daysExpected {
  display: flex;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(241, 113, 15, 0.2);
  color: #f1710f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}
