@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.metadataFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}

.viewClient-heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  border-radius: 8px 8px 0px 0px;
}

.viewClinet-Container {
  background-color: #fff;
  height: 100%;
}
