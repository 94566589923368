.payments-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.payments-buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
}
.payments-search{
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}
.payments-search input{
    width: 20%;
    outline-style: solid;
    outline-color: #787878;
    outline-width: 1px;
    padding-left: 12px;
    line-height: 32px;
    font-size: 15px;
}