.dropdown-clientSearch{
    gap: 2px;
    display: flex;
    flex-direction: column;
    width: 210px;
    position: absolute;
    background-color: white;
    top: 41px;
    right: 0px;
    z-index: 1;
    padding: 8px;
}