.workflowLeadAddAlbum-heading {
    background-color: rgb(27, 40, 53);
    color: #fff;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}

.workflowLeadAddAlbum-grid .row1 {
    grid-area: name;
    display: flex;
    flex-direction: row;
}

.workflowLeadAddAlbum-grid .row2 {
    grid-area: dropdown;
    display: flex;
    flex-direction: row;
}

.workflowLeadAddAlbum-grid .row5 {
    grid-area: buttons;
}

.workflowLeadAddAlbum-grid {
    display: grid;
    padding: 24px;
    gap: 10px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    grid-template-areas:
        'name name'
        'dropdown dropdown'
        'buttons buttons'
}

.workflowLeadAddAlbum-grid input {
    border-radius: 4px;
    /* width: 100%; */
    background-color: #e3e9ff;
    outline-style: solid;
    outline-color: #787878;
    outline-width: 1px;
    padding-left: 12px;
    line-height: 32px;
    font-size: 15px;
}

.workflowLeadAddAlbum-grid p {
    width: 100%;
    max-width: 120px;
    color: #787878;
}