.popupBackground {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(17px);
}
.SharePopupParent {
  display: flex;
  flex-direction: column;
  background: rgb(243, 243, 243);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  z-index: 15000;
  color: #ffffff;
  height: auto;
  width: 100%;
  max-width: 350px;
}

@media screen and (max-width: 582px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 3.125vw !important;
  }

  .grids {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px;
  }

  .flexico {
    display: flex;
    flex-direction: column !important;
  }
}

.nav {
  position: relative;
  width: 100%;
  height: 96px;
  padding: 24px 100px;
  top: 0px;
  background: linear-gradient(
    90.27deg,
    rgba(95, 0, 57, 0.3) 6.05%,
    rgba(28, 39, 80, 0.3) 98.64%
  );
  backdrop-filter: blur(84px);
}

.navData {
  display: flex;
  align-items: center;
  height: 48px;

  position: relative;
}

.icons {
  /* width: 98px; */
  display: flex;
  justify-content: left;
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
}

.donlode_app {
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  position: absolute;
  right: 0px;
  width: 228px;
  height: 48px;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}
.copy_app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 12px 20px; */
  gap: 10px;
  right: 0px;
  width: 108px;
  height: 48px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}

.arow {
  position: absolute;
  right: 20px;
}

.container {
  padding-left: 121px;
  padding-right: 121px;
  padding-top: 3.125vw;
}

.flexico {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grids {
  width: 306px;
  height: 544px;
  background: black;
  border-radius: 10px;
  position: relative;
  margin-left: 21px;
}

.posterData_3 {
  position: absolute;
  padding-left: 16px;
  padding-right: 16px;
  top: 139px;
}

.participer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 0;
}

.about {
  width: 273px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
}

.imgx {
  width: 100%;
  height: 280px;
  opacity: 0.9;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.posterData {
  padding-left: 16px;
  padding-top: 20px;
  padding-right: 16px;
}

.campaign {
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

.description {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
}

.org {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;
  color: #ffffff;
}

.eli {
  /* padding: 6px 12px; */
  margin-top: 30px;
  width: 100%;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f2f2f;
  border-radius: 30px;
}

.elitext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #eaeaea;
}

.txt_arrow {
  /* position: absolute; */
  margin-left: 5px;
  margin-top: 3px;
}

.imgx_3rd {
  width: 100%;
  object-fit: cover;
  height: 201px;
  left: 814px;
  top: 146px;
  opacity: 0.9;
  border-radius: 10px 10px 0px 0px;
}

.elix {
  margin-top: 37px;
  width: 100%;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottom_button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.bottomnav {
  width: 100%;
  height: 61px;
  text-align: center;
  padding: 20px 75px;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(92.38deg, #001561 0%, #6f0043 98.8%);
  border-radius: 0px 0px 10px 10px;
}

.linki {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #787878;
}

/* .buttonshare {
    width: 50px;
    height: 50px;    
    background: #252525;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
} */

.propic {
  width: 50px;
  height: 50px;
  border: 2px solid #171717;
  border-radius: 100px;
}

.flexiLikview {
  display: flex;
  justify-content: space-between !important;
}

.candidate {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #eaeaea;
}

.view {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #787878;
  margin-left: 10px;
}
.belowflex {
  display: flex;
  justify-content: center;
}
.leftico {
  margin-left: 10px;
}
.campaign:hover {
  color: #001561;
}
.owner {
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}
.ownerz {
  margin-left: 5px;
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}
.ownerz:hover {
  color: #001561;
}
