.sidebar {
  /* height: 100vh; */
  background-color: #ffffff;
  border-right: 1px solid rgba(140, 140, 140, 0.3);

}

.sidebar-link {
  display: flex;
  gap: 18px;
  padding: 8px 0px 8px 40px;
  margin: 0px 0px;
  text-decoration: none;
  align-items: center;
  color: #2d2c2c;
}

.sidebar-link.active {
  border-right: 3px solid #4d58e3;
  background: rgba(77, 88, 227, 0.1);
  color: #4d58e3;
}

.storage {
  padding: 10px 20px 10px 40px;
  color: #2d2c2c;

  /* Highlight Body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.storageusedText {
  color: #161616;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.poweredBy {
  color: rgba(0, 0, 0, 0.4);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 118.182% */
  letter-spacing: -0.22px;
}
