.addStudioFormContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}

.crewForm-heading {
  background-color: rgb(27, 40, 53);
  color: #fff;
  font-weight: 600;
  /* border-radius: 8px 8px 0px 0px; */
}

.crewForm-grid .row1 {
  grid-area: name;
  display: flex;
  flex-direction: row;
}

.crewForm-grid .row2 {
  grid-area: email;
  display: flex;
  flex-direction: row;
}

.crewForm-grid .row3 {
  grid-area: phone;
  display: flex;
  flex-direction: row;
}

.crewForm-grid .row4 {
  grid-area: whatsapp;
  display: flex;
  flex-direction: row;
}

.crewForm-grid .row5 {
  grid-area: buttons;
  display: flex;
  flex-direction: row;
}

.crewForm-grid {
  display: grid;
  padding: 34px;
  gap: 10px;
  width: 100%;
  /* border-radius: 0px 0px 8px 8px; */
  background-color: #fff;
  grid-template-areas:
    "name name"
    "email email"
    "phone phone"
    "whatsapp whatsapp"
    "buttons buttons";
}

.crewForm-grid input {
  border-radius: 4px;
  width: 100%;
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.crewForm-grid p {
  width: 100%;
  max-width: 100px;
  color: #787878;
}

.crewForm-grid label {
  width: 100%;
  max-width: 100px;
  color: #787878;
}

.crewForm-grid select {
  border-radius: 4px;
  width: 100%;
  background-color: #e3e9ff;
  outline-style: solid;
  outline-color: #787878;
  outline-width: 1px;
  padding-left: 12px;
  line-height: 32px;
  font-size: 15px;
}

.addStudioFormContainer .text {
  color: #161616;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}
.addStudioFormContainer .para {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}
.studioDetails {
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.studioForm label {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  line-height: 18px; /* 150% */
}

.studioForm input {
  display: flex;
  width: 302px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  outline: none;
  border-radius: 8px;
  margin-top: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
}
.studioFormsaveButton {
  width: 302px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #373cbd;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.studioFormCancelButton {
  width: 200px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  color: #111;
  font-family: Inter;
  
  border: 1px;
  border: 1px solid #787878 ;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.studioForm .logoText {
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.studioForm .logoBox {
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.2);
  /* background: #FFF; */
}

.studioForm .logoImgText {
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
  text-transform: uppercase;
}

.logoUploadText {
  color: #8c8c8c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}
