.mainDiv {
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  background: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
  left: 0;
  /* padding: 20px; */
  position: fixed;
  text-align: center;
  /* top: 0; */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
