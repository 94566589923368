.albumDetailDropDown {
  position: relative;
}

.albumDetailDropDownbutton {
  gap: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1px;
  z-index: 50;
  padding: 4px;
  width: max-content;
}

.albumDetailDropDownbutton button {
  background-color: white;
  color: black;
  font-size: 11px;
  font-weight: 400;
  border-bottom: solid 1px black;
  border-radius: 0%;
}

.albumDetailDropDownbutton  :hover {
  background-color: black;
  color: white;
}
