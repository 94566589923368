.navbar {
  border-top: 1px solid rgba(140, 140, 140, 0.3);
  border-bottom: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 48px 10px 40px;
  z-index: 10;
}

@keyframes highlight {
  0% {
    background-color: #34d399; /* Initial background color */
  }
  50% {
    background-color: #10b981; /* Intermediate background color */
  }
  100% {
    background-color: #34d399; /* Final background color */
  }
}

.animate-highlight {
  animation-name: highlight;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.searchBox {
  width: 271px;
  height: 40px;
  justify-items: start;
  align-items: center;

  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  background: #fff;
}

.packageTypeBut {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #e7e8fe;
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1f2aba;

  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
.packageTypeButNotActive {
  border-radius: 4px;
  border: 1px solid red;
  background: #e7e8fe;
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: red;

  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
