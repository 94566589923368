.albumUpload-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.albumUpload {
    background: #F5F8FC;
    border: 1px dashed #0064FF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.albumUpload-upload:hover {
    background-color: lightblue;
}