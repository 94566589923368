.WaterMarkContainer {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  filter: drop-shadow(0px 4px 34px rgba(130, 130, 130, 0.25));
  height: fit-content;
}
/* Hide number input spinners */
.hide-spin::-webkit-inner-spin-button,
.hide-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-spin {
  -moz-appearance: textfield; /* For Firefox */
}
.iconWatermark {
  border-radius: 10px;
  border: 1px solid #e4e7ec;
  background: #fff;

  /* Shadow / xs */
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.waterMarkPosition {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  /* width: 252px; */
  /* height: 152px; */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.waterMarkPositionButton {
  border-radius: 4px;

  display: flex;
  width: 73px;
  height: 20px;
  padding: 6px;
  justify-content: center;
  align-items: center;

  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
.changeWaterMark {
  display: inline-flex;
  padding: 8px 16px 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.cancelWaterMark {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;

  /* Shadow / xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.continueWaterMark {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #4d58e3;
  color: #fff;

  /* Text md / Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  background: #4d58e3;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
