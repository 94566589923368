.deleteClient {
    width: 544px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px 24px;
}

.deleteClient-top {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.deleteClient-top-text {
    display: flex;
    flex-direction: column;
}

.deleteClient-top-text1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #db0f0f;
}

.deleteClient-top-text2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #7e6262;
}

.deleteClient-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 12px;
}

.deleteClient-button {
    display: flex;
    flex-direction: row;
    gap: 12px;
}


.deleteClient-button1 {
    width: 90px;
    background: #373535;
    border: 1px solid #C7C8CA;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.deleteClient-button2 {
    width: 202px;
    background: #D92D20;
    border: 1px solid #D92D20;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}