.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;

  background: rgba(243, 243, 243, 0.95);
  /* padding-top: 78px; */
  overflow-y: auto;
}
.newPopUpWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;

  background: rgba(243, 243, 243, 0.95);
  /* padding-top: 78px; */
  overflow-y: auto;
}

.popupWrapperNew {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;

  background: rgba(37, 37, 37, 0.95);
  /* padding-top: 78px; */
  overflow-y: auto;
}
