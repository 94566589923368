.viewClient-heading {
    background-color: rgb(27, 40, 53);
    color: #fff;
    font-weight: 600;
    border-radius: 8px 8px 0px 0px;
}



.viewClinet-Container{
    background-color: #fff;
    height: 100%;
}