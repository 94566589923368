.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.pagination-container button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color:#2c8899;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

ul.page-numbers {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 2px;
  padding: 5px 8px;
  border: 1px solid #2c8899;
  border-radius: 0; /* Use square border-radius */
  cursor: pointer;
}

li.active {
  background-color: #2c8899;
  color: #fff;
}